@use '@angular/material' as mat;

html {
  @include mat.theme(
    (
      color: (
        primary: mat.$azure-palette,
        tertiary: mat.$red-palette,
        theme-type: light
      ),
      typography: Roboto,
      density: 0
    )
  );
}

@for $i from 0 through 24 {
  .mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body,
.mat-body,
.mat-subtitle-2,
.mat-typography {
  margin: 0;
  font: 400 16px/20px Roboto, "Helvetica Neue", sans-serif;
}
.highcharts-container {
  user-select: none;
  .highcharts-navigator-mask-inside {
    cursor: ew-resize;
    stroke: #c8ceff;
  }
  .highcharts-tooltip > span {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 8px;
  }
  .highcharts-navigator-handle {
    y: 3;
  }
}

button[mat-raised-button],
button[mat-button],
button[mat-flat-button] {
  mat-spinner.mat-mdc-progress-spinner {
    width: 20px !important;
    height: 24px !important;
    display: inline-block !important;
    svg {
      width: 20px !important;
      height: 24px !important;
    }
  }
}
